//
// Modal
//

@import "../core/modals/modal";

.custom-modal-body {
}
//.custom__modal-width {
//  width: 66rem;
//  margin-left: -38%;
//}
.custom__modal-width {
  width: 500px;
  height: 500px;
  margin-left: 30px;
}
.custom__modal-width canvas {
  width: 80% !important;
  height: 100% !important;
  position: relative !important;
}
.custom__canvas-wrapper {
  display: flex;
  justify-content: center;
}
.konvajs-content {
  height: 400px !important;
  width: 400px !important;
  display: flex;
  justify-content: center;
}
.custom__faSyncAlt {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  margin-top: 10px;
  margin-right: 20px;
  margin-left: 10px;
}
.test:hover {
  fill: #969393;
}
